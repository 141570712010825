var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.typeDevice === _vm.$options.TYPES_DEVICE.MOBILE)?_c('AppHeaderMobileMenu',{attrs:{"menu":_vm.menu,"is-menu-open":_vm.isMenuOpen,"menu-btn-selector":".header__menu-btn"},on:{"close-menu":function($event){_vm.isMenuOpen = false}}}):_vm._e(),(_vm.typeDevice === _vm.$options.TYPES_DEVICE.TABLET)?_c('AppHeaderTabletMenu',{attrs:{"menu":_vm.menu,"is-menu-open":_vm.isMenuOpen,"menu-btn-selector":".header__menu-btn"},on:{"close-menu":function($event){_vm.isMenuOpen = false}}}):_vm._e(),_c('header',{staticClass:"header"},[(_vm.typeDevice === _vm.$options.TYPES_DEVICE.DESKTOP)?_c('div',{staticClass:"header__preloader",class:{ 'header__preloader--active': _vm.isLoading }},[_c('img',{attrs:{"src":require("@/assets/graphics/app-preloader.svg"),"alt":""}})]):_vm._e(),_c('div',{staticClass:"header__container _container",class:{
        'header__container--padding':
          _vm.typeDevice !== _vm.$options.TYPES_DEVICE.DESKTOP,
      }},[(_vm.typeDevice !== _vm.$options.TYPES_DEVICE.DESKTOP)?_c('div',{ref:"menuBtn",staticClass:"header__menu-btn"},[_c('AppHeaderMenuBtn',{attrs:{"is-menu-open":_vm.isMenuOpen},on:{"click-menu-btn":function($event){_vm.isMenuOpen = !_vm.isMenuOpen}}})],1):_vm._e(),_c('div',{staticClass:"header__logo"},[(_vm.logoUrl)?[(_vm.externalLink)?_c('a',{attrs:{"href":_vm.externalLink,"target":"_blank"}},[(_vm.logoUrl)?_c('img',{attrs:{"src":_vm.logoUrl,"alt":"logo"}}):_vm._e()]):_c('img',{attrs:{"src":_vm.logoUrl,"alt":"logo"}})]:_vm._e()],2),(_vm.typeDevice === _vm.$options.TYPES_DEVICE.DESKTOP)?_c('nav',{staticClass:"header__nav"},[_c('AppHeaderDesktopMenu',{attrs:{"menu":_vm.menu,"window-with":_vm.windowWith}})],1):_vm._e(),_c('div',{staticClass:"header__helper"}),(_vm.typeDevice === _vm.$options.TYPES_DEVICE.DESKTOP)?_c('div',{ref:"manager",staticClass:"header__manager manager",on:{"mouseover":function($event){_vm.isManagerOpen = true},"mouseleave":function($event){_vm.isManagerOpen = false}}},[_c('button',{staticClass:"manager__btn"},[_c('IconUser',{style:(`color: ${
              _vm.isManagerOpen
                ? 'var(--s-primary-color)'
                : 'var(--s-black-color-80)'
            }`)})],1),_c('div',{staticClass:"manager__info",class:{ 'manager__info--active': _vm.isManagerOpen }},[_c('AppHeaderManager')],1)]):_vm._e(),(_vm.typeDevice !== _vm.$options.TYPES_DEVICE.MOBILE)?_c('div',{staticClass:"header__actions"},[_c('AppHeaderActions')],1):_vm._e()])]),_c('div',{staticClass:"_container"},[(_vm.$store.getters['isActiveEventIsChild'])?_c('div',{staticClass:"header-back",on:{"click":_vm.toEventList}},[_c('div',{staticClass:"header-back__content s-card s-card--no-padding"},[_c('span',{staticClass:"header-back__back-icon"},[_c('DirectionLeftIcon')],1),_c('span',{staticClass:"header-back__text s-subtitle-secondary"},[_vm._v(" "+_vm._s(_vm.$tr('sideBar.backToEvents'))+" ")])])]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }