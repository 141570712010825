export default {
  data() {
    return {
      prefixCls: 'ivu-color-picker',
      inputPrefixCls: 'ivu-input',
      iconPrefixCls: 'ivu-icon',
      transferPrefixCls: 'ivu-transfer',
    }
  },
}
