module.exports = {
  getOne: {
    data: {
      id: "16409",
      type: "user-menu",
      attributes: {
        userId: "16409",
        eventId: "1",
      },
      relationships: {
        pages: {
          data: [
            {
              id: "7397",
              type: "event-page",
            },
            {
              id: "7534",
              type: "event-page",
            },
            {
              id: "7594",
              type: "event-page",
            },
            {
              id: "7655",
              type: "event-page",
            },
            {
              id: "7682",
              type: "event-page",
            },
            {
              id: "7827",
              type: "event-page",
            },
            {
              id: "7871",
              type: "event-page",
            },
            {
              id: "15240",
              type: "event-page",
            },
            {
              id: "15341",
              type: "event-page",
            },
          ],
        },
        menuItems: {
          data: [
            {
              id: "1",
              type: "event-menu-item",
            },
            {
              id: "2",
              type: "event-menu-item",
            },
          ],
        },
      },
    },
    included: [
      {
        id: "7397",
        type: "event-page",
        attributes: {
          id: 7397,
          eventId: 1,
          type: "store",
          entityId: 2,
          title: "Магазин",
          active: true,
          limitedAccess: false,
          accessCount: 0,
          isStarted: false,
          lang: "ru",
          createdAt: "2021-06-09T18:24:32+0300",
          updatedAt: null,
        },
      },
      {
        id: "7534",
        type: "event-page",
        attributes: {
          id: 7534,
          eventId: 1,
          type: "static_page",
          entityId: 5,
          title: "Спикеры",
          active: true,
          limitedAccess: false,
          accessCount: 0,
          isStarted: false,
          lang: "ru",
          createdAt: "2021-06-09T18:24:32+0300",
          updatedAt: "2021-06-09T19:13:08+0300",
        },
      },
      {
        id: "7594",
        type: "event-page",
        attributes: {
          id: 7594,
          eventId: 1,
          type: "request",
          entityId: 35,
          title: "Заявка на участие в круглом столе",
          active: true,
          limitedAccess: false,
          accessCount: 0,
          isStarted: false,
          lang: "ru",
          createdAt: "2021-06-09T18:24:32+0300",
          updatedAt: null,
        },
      },
      {
        id: "7655",
        type: "event-page",
        attributes: {
          id: 7655,
          eventId: 1,
          type: "request",
          entityId: 132,
          title: "Заявка на участие в благотворительном забеге",
          active: true,
          limitedAccess: false,
          accessCount: 0,
          isStarted: false,
          lang: "ru",
          createdAt: "2021-06-09T18:24:32+0300",
          updatedAt: null,
        },
      },
      {
        id: "7682",
        type: "event-page",
        attributes: {
          id: 7682,
          eventId: 1,
          type: "stream",
          entityId: 9,
          title: "Тест 2",
          active: true,
          limitedAccess: false,
          accessCount: 0,
          isStarted: false,
          lang: "ru",
          createdAt: "2021-06-09T18:24:32+0300",
          updatedAt: "2021-09-27T16:13:26+0300",
        },
      },
      {
        id: "7827",
        type: "event-page",
        attributes: {
          id: 7827,
          eventId: 1,
          type: "stream",
          entityId: 124,
          title: "тест",
          active: true,
          limitedAccess: false,
          accessCount: 0,
          isStarted: false,
          lang: "ru",
          createdAt: "2021-06-21T16:01:51+0300",
          updatedAt: "2021-06-21T16:01:51+0300",
        },
      },
      {
        id: "7871",
        type: "event-page",
        attributes: {
          id: 7871,
          eventId: 1,
          type: "user_profile",
          entityId: null,
          title: "Профиль",
          active: true,
          limitedAccess: false,
          accessCount: 0,
          isStarted: false,
          lang: "ru",
          createdAt: "2021-06-09T18:24:32+0300",
          updatedAt: null,
        },
      },
      {
        id: "15240",
        type: "event-page",
        attributes: {
          id: 15240,
          eventId: 1,
          type: "user_orders",
          entityId: null,
          title: "Мои заказы",
          active: true,
          limitedAccess: false,
          accessCount: 0,
          isStarted: false,
          lang: "ru",
          createdAt: "2021-06-09T18:24:32+0300",
          updatedAt: null,
        },
      },
      {
        id: "15341",
        type: "event-page",
        attributes: {
          id: 15341,
          eventId: 1,
          type: "event_list",
          entityId: null,
          title: "Список мероприятий",
          active: true,
          limitedAccess: false,
          accessCount: 0,
          isStarted: false,
          lang: "ru",
          createdAt: "2021-06-11T16:28:59+0300",
          updatedAt: null,
        },
      },
      {
        id: "1",
        type: "event-menu-item",
        attributes: {
          eventMenuId: 1,
          lang: "ru",
          parentId: null,
          sort: 50,
          type: "url",
          url: "https://google.com",
          eventPageId: null,
          createdAt: "2021-08-20T16:02:17+0300",
          updatedAt: "2021-08-20T16:02:17+0300",
        },
      },
      {
        id: "2",
        type: "event-menu-item",
        attributes: {
          eventMenuId: 1,
          lang: "ru",
          parentId: 1,
          sort: 50,
          type: "page",
          url: null,
          eventPageId: 1, // возможно нужно будет поменять, если такой не существует
          createdAt: "2021-08-20T16:02:17+0300",
          updatedAt: "2021-08-20T16:02:17+0300",
        },
      },
    ],
  },
};
