import Menu from './menu.vue'
import MenuGroup from './menu-group.vue'
import MenuItem from './menu-item.vue'
import Submenu from './submenu.vue'

Menu.Group = MenuGroup
Menu.Item = MenuItem
Menu.Sub = Submenu

export default Menu
