<template>
  <div class="empty-layout">
    <div class="empty-layout__content">
      <router-view />
    </div>
    <AppMeroPlace v-if="!$store.getters['event/isMeroPlaceVisible']" />
  </div>
</template>

<script>
import AppMeroPlace from '@/components/common/app-mero-place'
export default {
  components: {
    AppMeroPlace,
  },
}
</script>

<style lang="scss">
@import '~@/styles/base/base-landing';
.empty-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &__content {
    flex: 1 1 auto;
  }
}
</style>
