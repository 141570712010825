<template>
  <div class="mero-place">
    <span class="mero-place__title"> {{ $tr('event.madeOn') }}</span>
    <div class="mero-place__logo">
      <a
        target="_blank"
        href="https://mero.place?utm_source=client&utm_medium=logo&utm_campaign=public_label"
        ><IconMeroPlace
      /></a>
    </div>
  </div>
</template>
<script>
import IconMeroPlace from '@/components/icons/icon-meroplace'

export default {
  components: {
    IconMeroPlace,
  },
}
</script>

<style lang="scss">
.mero-place {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
  padding: 0;
  margin: 0;
  height: 40px;
  background: var(--white-label);

  &__title {
    color: #a0a4a8;
    font-weight: 400;
    font-size: 12px;
  }

  &__logo {
    margin-left: 10px;
    max-height: 30px;
  }
}
</style>
