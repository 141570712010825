<template>
  <div id="theme" class="no-side-bar-layout">
    <div class="no-side-bar-layout__content container-fluid container-custom">
      <div class="main-content">
        <div class="container-fluid no-gutters pr-0 pl-0">
          <router-view />
        </div>
      </div>
    </div>
    <AppMeroPlace v-if="!$store.getters['event/isMeroPlaceVisible']" />
  </div>
</template>

<script>
import AppMeroPlace from '@/components/common/app-mero-place'
export default {
  components: {
    AppMeroPlace,
  },
}
</script>

<style lang="scss">
.no-side-bar-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &__content {
    flex: 1 1 auto;
  }
}
</style>
