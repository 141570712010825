<template>
  <div id="theme" class="default-layout">
    <AppHeader v-if="$isLoggedIn && $store.getters['event/currentEventId']" />

    <main class="default-layout__content main _container">
      <router-view />
    </main>
    <AppMeroPlace v-if="!$store.getters['event/isMeroPlaceVisible']" />
  </div>
</template>
<script>
import AppHeader from '@/components/base/header/app-header'
import AppMeroPlace from '@/components/common/app-mero-place'
export default {
  components: {
    AppMeroPlace,
    AppHeader,
  },
}
</script>

<style lang="scss">
@import '@/styles/base';
.default-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &__content {
    flex: 1 1 auto;
  }
}
</style>
