import BaseEndpoint from '@/api/endpoints/base-endpoint'

export default class RequestEndpoint extends BaseEndpoint {
  getRequestUser(params) {
    return this.request({
      url: `${this._url}`,
      method: 'GET',
      params,
    })
  }

  getCurrentRequestUser(id, params) {
    return this.request({
      url: `${this._url}/${id}`,
      method: 'GET',
      params,
    })
  }

  createRequestUser(data, params) {
    return this.request({
      url: `${this._url}`,
      method: 'POST',
      data,
      params,
    })
  }

  updateRequestUser(id, data, params) {
    return this.request({
      url: `${this._url}/${id}`,
      method: 'PATCH',
      data,
      params,
    })
  }

  deleteRequestUser(id) {
    return this.request({
      url: `${this._url}/${id}`,
      method: 'DELETE',
    })
  }
}
