<template>
  <ul class="ivu-dropdown-menu">
    <slot></slot>
  </ul>
</template>
<script>
export default {
  name: 'DropdownMenu',
}
</script>
