import setLang from '../lang'

const lang = {
  i: {
    locale: 'zh-CN',
    select: {
      placeholder: '请选择',
      noMatch: '无匹配数据',
      loading: '加载中',
    },
    table: {
      noDataText: '暂无数据',
      noFilteredDataText: '暂无筛选结果',
      confirmFilter: '筛选',
      resetFilter: '重置',
      clearFilter: '全部',
      sumText: '合计',
    },
    datepicker: {
      selectDate: '选择日期',
      selectTime: '选择时间',
      startTime: '开始时间',
      endTime: '结束时间',
      clear: '清空',
      ok: '确定',
      datePanelLabel: '[yyyy年] [m月]',
      month: '月',
      month1: '1 月',
      month2: '2 月',
      month3: '3 月',
      month4: '4 月',
      month5: '5 月',
      month6: '6 月',
      month7: '7 月',
      month8: '8 月',
      month9: '9 月',
      month10: '10 月',
      month11: '11 月',
      month12: '12 月',
      year: '年',
      weekStartDay: '0',
      weeks: {
        sun: '日',
        mon: '一',
        tue: '二',
        wed: '三',
        thu: '四',
        fri: '五',
        sat: '六',
      },
      months: {
        m1: '1月',
        m2: '2月',
        m3: '3月',
        m4: '4月',
        m5: '5月',
        m6: '6月',
        m7: '7月',
        m8: '8月',
        m9: '9月',
        m10: '10月',
        m11: '11月',
        m12: '12月',
      },
    },
    transfer: {
      titles: {
        source: '源列表',
        target: '目的列表',
      },
      filterPlaceholder: '请输入搜索内容',
      notFoundText: '列表为空',
    },
    modal: {
      okText: '确定',
      cancelText: '取消',
    },
    poptip: {
      okText: '确定',
      cancelText: '取消',
    },
    page: {
      prev: '上一页',
      next: '下一页',
      total: '共',
      item: '条',
      items: '条',
      prev5: '向前 5 页',
      next5: '向后 5 页',
      page: '条/页',
      goto: '跳至',
      p: '页',
    },
    rate: {
      star: '星',
      stars: '星',
    },
    time: {
      before: '前',
      after: '后',
      just: '刚刚',
      seconds: '秒',
      minutes: '分钟',
      hours: '小时',
      days: '天',
    },
    tree: {
      emptyText: '暂无数据',
    },
  },
}

setLang(lang)

export default lang
