import BaseEndpoint from '@/api/endpoints/base-endpoint'
import LogicError from '@/domain/errors/logic-error'
import jsonpAdapter from 'axios-jsonp'

const ghEndpointError = new LogicError('Данный метод не реализован в апи')
const ghToken =
  'aec210dbaec210dbaec210db65aea8893eaaec2aec210dbf21f7b278b79cc0a17c520cc'

export default class geohelperEndpoint extends BaseEndpoint {
  constructor(axiosConfig, url, type) {
    super(
      {
        ...axiosConfig,
        adapter: jsonpAdapter,
      },
      url,
      type,
    )
  }

  getCities(params = { country_id: 1 }) {
    return this.request({
      url: `https://geohelper.info/api/v1/cities`,
      params: {
        apiKey: 'nEWhZ75ovLhe4XxYMxfYTkAu16fzZYcV',
        'pagination[limit]': 100,
        ...params,
      },
    })
  }

  getCountries(params = {}) {
    return this.request({
      url: `https://geohelper.info/api/v1/countries`,
      params: {
        apiKey: 'nEWhZ75ovLhe4XxYMxfYTkAu16fzZYcV',
        ...params,
      },
    })
  }

  getOne(id, params = {}) {
    throw ghEndpointError
  }

  getMany(params = {}) {
    throw ghEndpointError
  }

  create(data = {}, params = {}) {
    throw ghEndpointError
  }

  update(id, data = {}, params = {}) {
    throw ghEndpointError
  }

  delete(id) {
    throw ghEndpointError
  }
}
